import homeCss from '../styles/home.css?url';

import NavBar from '~/components/home/NavBar';
import { Image } from '@nextui-org/react';
import { user } from '~/services/auth.server';
import { useLoaderData } from '@remix-run/react';

export const links = () => [{ rel: 'stylesheet', href: homeCss }];

export const loader = async ({ request }) => {
  let response = await user({ request });
  console.log('in index  loader');
  return { user: response };
};

export default function Index() {
  const { user } = useLoaderData();
  return (
    <div>
      <NavBar user={user} />
      <div className="flex items-center justify-center">
        <Image
          width={1080}
          src="https://lacasatour.b-cdn.net/images/1702995443740_571sbarfielddrmarcoislandfl341453.jpg?class=wmark"
        />
      </div>
    </div>
  );
}


